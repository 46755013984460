module LS.Client3DEditor.Ajax {

    const cacheKeyEditor3DData = 'Editor3DData';
    const cacheKeyEditorClientObjectData = 'EditorClientObjectData';

    var initialized = false;

    export var defaultModuleAppearance: IModuleAppearance = null;
    export var selectableModuleIds: string[] = null;

    export var clientObjectConfiguration: { [type: string]: IClientObjectConfiguration } = {};

    var layoutClientObjectType: string = null;
    var layoutModuleId: string = null;

    var loadingManager = new THREE.LoadingManager();

    export function init(roofTexturesLoaded: () => void) {
        if (initialized)
            return;
        initialized = true;

        loadingManager.onLoad = roofTexturesLoaded;

        var bindings: AManager.IAjaxBindings = {
            "/WebServices/AppServices.asmx/CheckLoadBackup": null,
            "/WebServices/AppServices.asmx/SaveKonstrukctionValuesAnordnung": null,
            "/Areas/Electric/WebServices/ElectricServices.asmx/CreateModuleConnection": null,
            "/Areas/Electric/WebServices/ElectricServices.asmx/DeleteModuleConnection": null,
            "/Areas/Electric/WebServices/ElectricServices.asmx/GetConduitComponentSets": null,
            "/Areas/Electric/WebServices/ElectricServices.asmx/SetConduitObjects": null,
            "/WebServices/ShadeService.asmx/CalculateAllShadowObjects": null,
            "/WebServices/ShadeService.asmx/DeleteShadowObjects": null,
            "/WebServices/ShadeService.asmx/HasShadowsToCalculate": null,
            "/WebServices/Anordnung3DService.asmx/GetAnlagen": null,
            "/WebServices/Anordnung3DService.asmx/GetAnordnungObjects": GetAnordnungObjects,
            "/WebServices/Anordnung3DService.asmx/GetAutoCADObjects": null,
            "/WebServices/Anordnung3DService.asmx/GetCurrentRoofObject": GetCurrentRoofObject,
            "/WebServices/Anordnung3DService.asmx/GetElectricObjects": null,
            "/WebServices/Anordnung3DService.asmx/GetStaticObjects": null,
            "/WebServices/Anordnung3DService.asmx/HandleClientObjectChanges": { method: HandleClientObjectChanges, onFinished: SaveChanges },
            "/WebServices/Anordnung3DService.asmx/SetInterferenceStates": { method: SetInterferenceStates, onFinished: SaveInterferences },
            "/WebServices/Anordnung3DService.asmx/ChangeModuleObjectAppearance": null,
            "/WebServices/Anordnung3DService.asmx/ChangeModuleObjectTypes": null,
            "/WebServices/Anordnung3DService.asmx/DeleteClientObjects": { method: DeleteClientObjects, onFinished: SaveChanges },
            "/WebServices/Anordnung3DService.asmx/DeleteDimensionAligned": null,
            "/WebServices/Anordnung3DService.asmx/FillWithGetClient3DObects": null,
            "/WebServices/Anordnung3DService.asmx/GenerateWithGetClient3DObects": GenerateWithGetClient3DObects,
            "/WebServices/Anordnung3DService.asmx/GetAllClient3DObects": null,
            "/WebServices/Anordnung3DService.asmx/InsertClientObjects": { method: InsertClientObjects, onFinished: SaveChanges },
            "/WebServices/Anordnung3DService.asmx/InsertClientObjectByRect": { method: InsertClientObjectByRect, onFinished: SaveChanges },
            "/WebServices/Anordnung3DService.asmx/ResetConstructed": null,
            "/WebServices/Anordnung3DServiceBuildings.asmx/EditBuilding": null,
            "/WebServices/Anordnung3DServiceBuildings.asmx/EditBuildings": null,
            "/WebServices/Anordnung3DServiceBuildings.asmx/GetBuilding": null,
            "/WebServices/Anordnung3DServiceBuildings.asmx/RemoveBuildings": null,
            "/WebServices/ProjectServices.asmx/SwitchInstanceholder": null
        };

        AManager.RegisterAjaxBindings(bindings);

    }

    //#region save and load

    async function GetAnordnungObjects(compressData: boolean) {
        var { result, editorData, clientObjectData } = await GetEditorData();

        await Promise.all([SaveDataForEditor3D(editorData), SaveDataForClientObjects(clientObjectData)]);

        return result;
    }

    async function GetCurrentRoofObject() {
        var { result } = await GetEditorData();

        return result;
    }

    async function GetEditorData(withClientObjects = true) {
        var instanceHolderId = spt.Utils.GenerateGuid();

        var result: SolarProTool.IEditorViewObject = {
            InstanceHolderId: instanceHolderId,
            InstanceHolderConfiguration: {
                PvModuleId: null,
                InstanceHolderType: "RoofAreaObject",
                MountingSystemType: "FixationTypeObject",
                MountingSystemId: null,
                IsElevation: false,
                IsEastWest: false
            },
            RoofObject: {
                LayerType: null,
                position: null,
                rotation: null,
                quaternion: null,
                scale: null,
                visible: true,
                castShadow: false,
                receiveShadow: false,
                layers: null,
                renderOrder: 0,
                Id: null,
                ThreeJSType: "Object3D"
            },
            RoofPolygons: [],
            SurfacePolygons: null,
            RoofWidth: 17137,
            RoofHeight: 7863,
            BoundsWidth: 17137,
            BoundsHeight: 7863,
            BoundsDepth: 21000,
            BoundsX: 0,
            BoundsY: 0,
            BoundsZ: -16677.300115434184,
            RoofOrientation: 164.96,
            GlobalOrientationRadian: 0.26249751949994706,
            Orientation: 164.96,
            LayoutOrientation: 0,
            LayoutOrientationMoveToZero: {
                x: 0,
                y: 0,
                z: 0,
                ThreeJSType: "Vector3"
            },
            RoofAngle: 33.35,
            RoofAngleX: 0.286942563603662,
            RoofAngleY: 0,
            RoofAngleZ: 0,
            RoofAngleW: 0.9579477883436854,
            ModuleNominalPower: 325,
            HasShadows: false,
            ClientObjects: [],
            RoofAreaStartX: "100",
            RoofAreaStartY: "100",
            RoofAreaDistanceToRoofBorder: "",
            GroundZ: -16677.300115434184,
            IsElevation: false,
            ElevationType: null,
            FixationType: "GenericFixationLayoutCalculator",
            RoofBackgroundType: 0,
            HasMapsBackground: true,
            DisplayMode: 0,
            Layers: null,
            MeasureObjects: [],
            BuildingClassName: "SolarCalcObjects.BuildingObject",
            BuildingSettings: [],
            LatLngPolygon: [],
            WorldOrigin: {
                Latitude: 46.62681167293274,
                Longitude: 14.300340820531794
            },
            WorldCenter: {
                Latitude: 46.6268601385711,
                Longitude: 14.30043791760981
            },
            EditorViewObjects: [],
            ProcessStep: 5,
            HeatmapDataPoints: null,
            HeatMapMinValue: 0,
            HeatMapMaxValue: 100,
            HeatMapRadius: 10,
            ConduitObjects: null,
            DirectedAreas: [],
            InterferenceObjects: []
        };

        return (await setData(result, withClientObjects));
    }

    async function setData(result: SolarProTool.IEditorViewObject, withClientObjects: boolean) {
        let [editorData, clientObjectData] = await Promise.all([GetDataForEditor3D(), GetDataForClientObjects()]);

        //if (!editorData)
        //	editorData = extractMapDrawerData(await MapDrawing.Ajax.GetDataForMapDrawer());

        if (!editorData)
            return { result, editorData, clientObjectData };

        var pvModuleId: string,
            clientObjectType: string,
            pvModule: IPVModule,
            pvModuleViewmodel = LS.Client3DEditor.Controller.Current.viewModel.additionalViewmodels["PVModuleViewmodel"] as lsAo3d.PVModuleViewmodel;

        //debugger;

        if (!clientObjectData || !clientObjectData.clientObjects.length || !clientObjectData.clientObjects.some(co => !!co.positions.length) || !clientObjectConfiguration[clientObjectData.clientObjects[0].config.ClientObjectType]) {
            //automatic layout if no clientobjects present
            pvModuleId = selectableModuleIds[0];
            clientObjectType = getDefaultModuleType(editorData.roofType); // Object.keys(clientObjectConfiguration)[0];
            pvModule = await lsAo3d.getPvModule(pvModuleId);
            clientObjectData = await generateLayout(result.InstanceHolderId, editorData, clientObjectType, pvModule);
        } else {
            pvModule = clientObjectData.pvModule;
            pvModuleId = pvModule.PVModuleId;
            clientObjectType = getDefaultModuleType(editorData.roofType); // clientObjectData.clientObjects[0].config.ClientObjectType;
            result.InstanceHolderId = clientObjectData.instanceHolderId;
        }

        var config = clientObjectConfiguration[clientObjectType];

        result.ModuleNominalPower = pvModule.MODULENOMINALPOWER;

        //TODO was wenn modul nicht gefunden?
        pvModuleViewmodel.setModuleById(pvModule.PVModuleId);

        result.RoofObject = buildRoofObject(editorData.poly, editorData.roofAngle, editorData.roofHeight);

        result.RoofAngle = editorData.roofAngle;


        var roofAngle = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), editorData.roofAngle / 180 * Math.PI);

        var v = new THREE.Vector3(),
            bounds = new MapDrawing.Bounds2D(editorData.bounds),
            boundsLatLng = new MapDrawing.BoundsLatLng(editorData.boundsLatLng);

        var upperZ = editorData.poly.max(p => v.set(p.x, p.y, 0).applyQuaternion(roofAngle).z);
        var lowerZ = Math.min(0, upperZ - editorData.roofHeight);

        result.GroundZ = lowerZ;

        result.RoofAngleX = roofAngle.x;
        result.RoofAngleY = roofAngle.y;
        result.RoofAngleZ = roofAngle.z;
        result.RoofAngleW = roofAngle.w;

        result.LatLngPolygon = editorData.poly.map(p => { return { Latitude: p.latitude, Longitude: p.longitude }; });
        result.WorldOrigin = { Latitude: boundsLatLng.Min.Latitude, Longitude: boundsLatLng.Min.Longitude };
        result.WorldCenter = { Latitude: boundsLatLng.Center.Latitude, Longitude: boundsLatLng.Center.Longitude };

        result.RoofPolygons = [editorData.poly.map(p => new THREE.Vector3(p.x, p.y, 0))];
        result.RoofAreaPolygons = offsetPolygon(result.RoofPolygons, -config.DistanceToRoofBorder);
        result.RoofWidth = bounds.Delta.x;
        result.RoofHeight = bounds.Delta.y;
        result.BoundsWidth = bounds.Delta.x;
        result.BoundsHeight = bounds.Delta.y;
        result.BoundsDepth = editorData.buildingHeight;
        result.BoundsX = 0;
        result.BoundsY = 0;
        result.BoundsZ = lowerZ;

        if (withClientObjects) {
            result.ClientObjects = buildClientObjects(clientObjectData);
            result.InterferenceObjects = mapShapesToInterferences(result.InstanceHolderId, editorData.interferenceMapShapes);
        }

        return { result, editorData, clientObjectData };
    }

    export async function EraseDataForEditor3D() {
        await localforage.removeItem(cacheKeyEditor3DData);
    }

    export async function GetDataForEditor3D() {
        return (await localforage.getItem<IEditorData>(cacheKeyEditor3DData)) || null;
    }

    export async function SaveDataForEditor3D(data: IEditorData) {
        await localforage.setItem(cacheKeyEditor3DData, data || null);
    }

    export async function EraseDataForClientObjects() {
        await localforage.removeItem(cacheKeyEditorClientObjectData);
    }

    export async function GetDataForClientObjects() {
        return (await localforage.getItem<IEditorClientObjectData>(cacheKeyEditorClientObjectData)) || null;
    }

    export async function SaveDataForClientObjects(data: IEditorClientObjectData) {
        await localforage.setItem(cacheKeyEditorClientObjectData, data || null);
    }

    export async function SaveChanges() {
        LoaderManager.addLoadingJob();

        await asyncTimeout(0);

        let controller = LS.Client3DEditor.Controller.Current,
            clientObjectData = await GetDataForClientObjects(),
            pvModule = clientObjectData.pvModule,
            instanceHolderId = clientObjectData.instanceHolderId,
            clientObjects = Object.keys(controller.Objects).map(key => controller.Objects[key]),
            moduleAppearance = generateAppearanceFromModule(pvModule);

        var clientObjectsData: IClientObjectsData[] = clientObjects.map(clientObject => {
            var instances = clientObject.ClientObjectInstances,
                config = clientObjectConfiguration[clientObject.dataType],
                { width, height } = calculateModuleDimension(config, pvModule.MODULEWIDTH, pvModule.MODULELENGTH),
                positions = instances.map(inst => { return { X: inst.instanceData.X, Y: inst.instanceData.Y }; });
            return { positions, width, height, config, moduleAppearance };
        });

        clientObjectData = {
            instanceHolderId,
            pvModule,
            clientObjects: clientObjectsData
        };

        await SaveDataForClientObjects(clientObjectData);

        LoaderManager.finishLoadingJob();
    }

    export async function SaveInterferences() {
        LoaderManager.addLoadingJob();

        var [editorData, mapDrawerData] = await Promise.all([GetDataForEditor3D(), MapDrawing.Ajax.GetDataForMapDrawer()]),
            roofShape = editorData.roofShape,
            controller = LS.Client3DEditor.Controller.Current,
            viewModel = controller.viewModel,
            interferenceObjects = viewModel.interferenceObjects as DynamicInterferenceObject[],
            roofPoly = roofShape.borders[0],
            roofCoords = roofPoly.points,
            slopeRad = roofPoly.slope / 180 * Math.PI,
            orientationRad = MapDrawing.calculateOrientationRad(roofCoords),
            worldOrigin = MapDrawing.calculateWorldOrigin(orientationRad, roofCoords),
            interMapShapes = interferenceObjects.map(inter => interferenceToMapShape(inter, worldOrigin, orientationRad, slopeRad, true));

        //replace interference shapes
        mapDrawerData.Shapes = mapDrawerData.Shapes.filter(s => !s.TypeName.startsWith(InterfernceTypeName)).concat(interMapShapes);

        await MapDrawing.Ajax.SaveMapDrawerData({ data: mapDrawerData });

        LoaderManager.finishLoadingJob();
    }

    //#endregion

    //#region roofobject, interferences and module layout

    export function calculateModuleDimension(config: IClientObjectConfiguration, moduleWidth: number, moduleHeight: number) {
        var width: number,
            height: number;

        if (config.ModuleHorizontal) {
            width = moduleHeight;
            height = moduleWidth;
        } else {
            width = moduleWidth;
            height = moduleHeight;
        }

        switch (config.GeneratorType) {
            case "Module":
                break;
            case "NorthSouth":
                height = Math.cos(config.ElevationAngle / 180 * Math.PI) * height;
                break;
            case "EastWest":
                width = Math.cos(config.ElevationAngle / 180 * Math.PI) * width * 2 + config.DistanceBetweenLeftAndRight;
                break;
        }

        if (config.GeometricWidth > 0)
            width = config.GeometricWidth;

        if (config.GeometricHeight > 0)
            height = config.GeometricHeight;

        width += config.GeometricMarginX;
        height += config.GeometricMarginY;

        return { width, height };
    }

    export async function generateLayout(instanceHolderId: string, data: IEditorData, clientObjectType: string, pvModule: IPVModule, layoutMask?: SolarProTool.Point2D[], additionalInterferences: SolarProTool.Point2D[][] = []) {
        var config = clientObjectConfiguration[clientObjectType],
            roofPolys: SolarProTool.Point2D[][] = data.roofShape.borders.map(b => b.points.map(p => { return { X: p.x, Y: p.y } })),
            interPolys: SolarProTool.Point2D[][] = [],
            { width, height } = calculateModuleDimension(config, pvModule.MODULEWIDTH, pvModule.MODULELENGTH);

        data.interferenceMapShapes.forEach(inter => {
            interPolys.pushAll(inter.borders.map(b => b.points.map(p => { return { X: p.x, Y: p.y } })));
        });

        var layoutResult = await lsAo3d.calculateLayout({
            LayoutAreas: roofPolys,
            Interferences: interPolys.concat(additionalInterferences),
            LayoutMask: layoutMask || null,
            LayoutShapeWidth: width,
            LayoutShapeHeight: height,
            Config: config
        });

        var positions = layoutResult.positions;

        var moduleAppearance = generateAppearanceFromModule(pvModule);

        var result: IEditorClientObjectData = {
            instanceHolderId,
            pvModule,
            clientObjects: [{ positions, width, height, config, moduleAppearance }]
        };

        return result;
    }

    export function extractMapDrawerData(data: SolarProTool.MapDrawerData) {
        if (!data)
            return null;

        var mapShapes = data.Shapes;

        if (!mapShapes || !mapShapes.length)
            return null;

        var { boundsLatLng, bounds } = MapDrawing.extractBounds(mapShapes);
        if (!boundsLatLng.isValid())
            return null;

        var buildingShape = mapShapes.find(s => s.TypeName == BuildingTypeName && s.borders != null && s.borders.length);
        var buildingOrientation = 180;

        if (buildingShape.mapSouthMarker != null) {
            var buildingOrientationRad = buildingShape.mapSouthMarker.Orientation;
            var buildingOrientation = (180 - buildingOrientationRad / Math.PI * 180) % 360;
            if (buildingOrientation < 0)
                buildingOrientation += 360;
            buildingOrientation = Math.round(buildingOrientation * 100) / 100;
        }

        var buildingBorder = buildingShape.borders[0];
        var useProjection = buildingBorder.useProjection;
        var buildingPoints = buildingBorder.points;

        if (buildingPoints == null || buildingPoints.length <= 2)
            return null;

        if (buildingBorder.isClockwise) {
            buildingPoints.reverse();
            buildingBorder.isClockwise = false;
        }

        var latitude = buildingPoints.sum(p => p.latitude) / buildingPoints.length;
        var longitude = buildingPoints.sum(p => p.longitude) / buildingPoints.length;

        var projectLocation = new MapDrawing.LatLng(latitude, longitude);

        if (!projectLocation.IsValid())
            return null;

        var roofShapes = mapShapes.filter(s => s.TypeName == RoofTypeName && s.borders != null && s.borders.length);

        if (!roofShapes.length)
            return null;

        var buildingHeight = roofShapes.max(s => s.borders[0].points.max(p => p.z));
        var roofShape = roofShapes.find(s => s.IsActive);

        if (!roofShape)
            return null;

        var border = roofShape.borders[0];

        var roofAngle = border.slope;

        var roofType = border.slope > 5 ? LS.Client3DEditor.RoofType.SlopingRoof : LS.Client3DEditor.RoofType.FlatRoof;

        var poly = border.points;

        if (!poly || poly.length <= 2)
            return null;

        if (border.isClockwise) {
            poly.reverse();
            border.isClockwise = false;
        }

        var roofHeight = poly.max(p => p.z);

        var interferenceMapShapes = mapShapes.filter(s => s.TypeName.startsWith(InterfernceTypeName));

        var result: IEditorData = {
            buildingOrientation,
            buildingHeight,
            projectLocation: { Latitude: projectLocation.Latitude, Longitude: projectLocation.Longitude },
            useProjection,
            roofAngle,
            roofType,
            roofShapes,
            roofShape,
            poly,
            boundsLatLng: { Min: { Latitude: boundsLatLng.Min.Latitude, Longitude: boundsLatLng.Min.Longitude }, Max: { Latitude: boundsLatLng.Max.Latitude, Longitude: boundsLatLng.Max.Longitude } },
            bounds: { Min: { X: bounds.Min.X, Y: bounds.Min.Y }, Max: { X: bounds.Max.X, Y: bounds.Max.Y } },
            roofHeight,
            interferenceMapShapes
        };

        return result;
    }

    export function offsetPolygon(polys: { x: number, y: number, z?: number }[][], offset: number, pointPrecision = 1000) {
        var arcSamplingLength = 10, //10 mm
            paths = spt.ThreeJs.utils.PointsToPaths(polys, pointPrecision),
            offsetPaths = new ClipperLib.Paths(),
            co = new ClipperLib.ClipperOffset(),
            dist = offset * pointPrecision;

        co.ArcTolerance = Math.abs(offset) * pointPrecision * (1 - Math.cos(arcSamplingLength / Math.abs(offset) * 0.5));
        co.AddPaths(paths, ClipperLib.JoinType.jtRound, ClipperLib.EndType.etClosedPolygon);
        co.Execute(offsetPaths, dist);

        return spt.ThreeJs.utils.PathsToPoints(offsetPaths, pointPrecision);
    }

    export function buildRoofObject(poly: SolarProTool.MapCoord[], slope: number, height: number) {

        var obj = new THREE.Object3D(),
            textureLoader = new THREE.TextureLoader(loadingManager);
        if (!poly)
            return obj;

        var latLngs = poly.map(p => new MapDrawing.LatLng(p.latitude, p.longitude)),
            tilemapCalculator = new MapDrawing.TiledMapCalculator(latLngs, 21),
            tilemapCalculatorSides = new MapDrawing.TiledMapCalculator(latLngs, 19, 32),
            geo = new THREE.Geometry(),
            geoSides = new THREE.Geometry(),
            index = 0;

        //build geometry
        var count = poly.length;

        var uvs = tilemapCalculator.CalculateUvs(poly.map(p => new MapDrawing.LatLng(p.latitude, p.longitude))),
            uvsSides = tilemapCalculatorSides.CalculateUvs(poly.map(p => new MapDrawing.LatLng(p.latitude, p.longitude))),
            vIndizes = THREE.ShapeUtils.triangulateShape(poly.map(p => new THREE.Vector2(p.x, p.y)), []),
            slopeRad = slope / 180 * Math.PI,
            transform = new THREE.Matrix4().makeRotationX(slopeRad),
            upperZ = 0;

        for (var i = 0; i < count; i++) {
            var v = poly[i],
                p = new THREE.Vector3(v.x, v.y, 0).applyMatrix4(transform);
            upperZ = Math.max(upperZ, p.z);
            geo.vertices.push(p);
            geoSides.vertices.push(p);
        }

        var lowerZ = Math.min(0, upperZ - height);

        for (var m = 0, l2 = vIndizes.length; m < l2; m++) {
            var ind = vIndizes[m];

            var a = ind[0], b = ind[1], c = ind[2];

            geo.faces.push(new THREE.Face3(a + index, b + index, c + index));
            geoSides.faces.push(new THREE.Face3(a + index + count, c + index + count, b + index + count));

            geo.faceVertexUvs[0].push([new THREE.Vector2(uvs[a].x, uvs[a].y), new THREE.Vector2(uvs[b].x, uvs[b].y), new THREE.Vector2(uvs[c].x, uvs[c].y)]);
            geoSides.faceVertexUvs[0].push([new THREE.Vector2(uvsSides[a].x, uvsSides[a].y), new THREE.Vector2(uvsSides[c].x, uvsSides[c].y), new THREE.Vector2(uvsSides[b].x, uvsSides[b].y)]);
        }

        for (var i = count - 1, j = 0; j < count; j++) {

            var a = i + count,
                b = j + count,
                c = j,
                d = i;

            geoSides.vertices.push(geo.vertices[j + index].clone().setZ(lowerZ));

            geoSides.faces.push(new THREE.Face3(a + index, b + index, c + index));
            geoSides.faces.push(new THREE.Face3(a + index, c + index, d + index));

            geoSides.faceVertexUvs[0].push([new THREE.Vector2(uvsSides[i].x, uvsSides[i].y), new THREE.Vector2(uvsSides[j].x, uvsSides[j].y), new THREE.Vector2(uvsSides[j].x, uvsSides[j].y)]);
            geoSides.faceVertexUvs[0].push([new THREE.Vector2(uvsSides[i].x, uvsSides[i].y), new THREE.Vector2(uvsSides[j].x, uvsSides[j].y), new THREE.Vector2(uvsSides[i].x, uvsSides[i].y)]);

            i = j;
        }

        geo.computeFaceNormals();
        geo.computeBoundingSphere();

        geoSides.computeFaceNormals();
        geoSides.computeBoundingSphere();

        index = geo.vertices.length;

        //https://localhost:44324/handler/StaticImagesHandler.ashx?ID=GoogleSatellite&center=-33.86464287175366,151.20835304260254&zoom=21&size=256x256&scale=1
        var map = spt.ThreeJs.JsonConverter.loadTextureFromUrlStatic("/handler/StaticImagesHandler.ashx?" + tilemapCalculator.GetMapsUrlParameter("GoogleSatellite"), textureLoader);
        map.anisotropy = 16;
        map.minFilter = THREE.LinearFilter;

        var mat = new THREE.MeshLambertMaterial({
            color: new THREE.Color(0xFFFFFF),
            fog: false,
            map: map,
            transparent: false,
            side: THREE.FrontSide
        });

        var mesh = new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(geo), mat);

        obj.add(mesh);

        var mapBlurred = spt.ThreeJs.JsonConverter.loadTextureFromUrlStatic("/handler/StaticImagesHandler.ashx?" + tilemapCalculatorSides.GetMapsUrlParameter("GoogleSatellite"), textureLoader);
        mapBlurred.minFilter = THREE.LinearFilter;

        var matSides = new THREE.MeshLambertMaterial({
            color: new THREE.Color(0xFFFFFF),
            fog: false,
            map: mapBlurred,
            transparent: false,
            side: THREE.FrontSide
        });

        var meshSides = new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(geoSides), matSides);

        obj.add(meshSides);

        return obj;
    }

    export function mapShapesToInterferences(baseParentId: string, mapShapes: SolarProTool.MapShape[]) {
        var index = 1;

        var result = mapShapes.map(mapShape => {
            var poly = mapShape.borders[0],
                data: number[] = [];

            if (poly.isClockwise) {
                poly.isClockwise = false;
                poly.points.reverse();
            }

            poly.points.forEach(p => { data.push(p.x, p.y, p.z); });

            var res: SolarProTool.IInterference3DObject = {
                IsParallel: mapShape.IsParallel,
                Name: mapShape.Title || ("Störfläche " + index),
                Type: "DynamicInterferenceObject",
                TypeId: null,
                Settings: null,
                BaseParentId: baseParentId.toLowerCase(),
                IdString: (mapShape.IdString || spt.Utils.GenerateGuid()).toLowerCase(),
                ClientOptions: 0,
                Data: data
            };

            ++index;

            return res;
        });

        return result;
    }

    export function interferenceToMapShape(inter: DynamicInterferenceObject, globalOrigin: MapDrawing.LatLng, globalOrientationRad = 0, slopeRad = 0, useProjection = true) {
        var poly = inter.localPolygon.array.map(p => new MapDrawing.Point2D(p.x, p.y)),
            coords = MapDrawing.calculateGlobalCoordinates(poly, globalOrigin, globalOrientationRad, useProjection ? slopeRad : 0, inter.height, 0),
            latLngs = coords.map(c => new MapDrawing.LatLng(c.latitude, c.longitude)),
            boundsLatLng = MapDrawing.BoundsLatLng.FromLatLngs(latLngs),
            bounds = MapDrawing.Bounds2D.FromPoints(poly),
            mapPoly: SolarProTool.MapPolygon = {
                points: coords,
                isClockwise: MapDrawing.Point2D.isClockwise(poly),
                minLat: boundsLatLng.Min.Latitude,
                minLng: boundsLatLng.Min.Longitude,
                maxLat: boundsLatLng.Max.Latitude,
                maxLng: boundsLatLng.Max.Longitude,
                minx: bounds.Min.x,
                miny: bounds.Min.y,
                maxx: bounds.Max.x,
                maxy: bounds.Max.y,
                width: bounds.Delta.x,
                height: bounds.Delta.y,
                slope: 0,
                useProjection: useProjection
            };

        var result: SolarProTool.MapShape = {
            IdString: inter.IdString.toLowerCase(),
            TypeName: InterfernceTypeName,
            Title: inter.Name || "",
            borders: [mapPoly],
            IsParallel: inter.IsParallel,
            mapSouthMarker: null,
            IsActive: true,
            separationPolylines: null,
            cuttingPolylines: null,
            SlopeOverride: false
        };

        return result;
    }

    //#endregion

    //#region generate client objects (geometry, material, etc.)

    function calculateBounds(clientObject: ClientObject, x = 0, y = 0, target?: THREE.Box3) {
        if (!target)
            target = new THREE.Box3();

        let bb = clientObject.ClientObjectGeometry.boundingBox,
            sizeX = 1, //instanceData.SizeX,
            sizeY = 1, //instanceData.SizeY,
            w = clientObject.userData.GeoWidth || ((bb.max.x - bb.min.x) * sizeX),
            h = clientObject.userData.GeoHeight || ((bb.max.y - bb.min.y) * sizeY),
            d = bb.max.z;

        target.min.set(x, y, 0);
        target.max.set(x + w, y + h, d);

        return target;
    }

    export function generateAppearanceFromModule(pvModule: IPVModule): IModuleAppearance {
        return $.extend({}, defaultModuleAppearance, {
            appearanceWidth: pvModule.MODULEWIDTH,
            appearanceHeight: pvModule.MODULELENGTH,
            appearanceDepth: pvModule.MODULETHICKNESS
        });
    }

    function getModuleAppearance(mod?: IModuleAppearance, modWidth = 900, modHeight = 1900, notInstanced = false, blending = THREE.NormalBlending, opacity = 1, reflection: THREE.CubeTexture = null, reflect90Degree = false, makeGray = false) {
        if (!mod)
            mod = defaultModuleAppearance;

        var frameColor = new THREE.Color(mod.moduleFramingDrawingColor);
        var color = new THREE.Color(mod.moduleDrawingColor);
        var backsheetColor = new THREE.Color(mod.moduleBacksheetDrawingColor);

        var cellType = mod.moduleCellType;
        var cellRows = mod.solarCellRows;
        var cellCols = mod.solarCellColumns;
        var framingWidth = mod.moduleFramingWidth;
        var dimension = new THREE.Vector2(modWidth, modHeight);
        var cellEdgeRound = mod.solarCellEdgeRoundness;
        var cellEColumn = mod.solarCellECols;
        var cellPadding = mod.solarCellPadding;
        var cellWidth = (modWidth - framingWidth * 2) / cellCols;
        var cellHeight = (modHeight - framingWidth * 2) / cellRows;

        if (makeGray) {
            frameColor = spt.ThreeJs.utils.colorToGrayscale(frameColor);
            color = spt.ThreeJs.utils.colorToGrayscale(color);
            backsheetColor = spt.ThreeJs.utils.colorToGrayscale(backsheetColor);
        }

        return new THREE.ModuleAppearanceShader(cellType, color.getHex(), cellCols, cellRows, dimension.x, dimension.y, framingWidth, notInstanced, blending, opacity, reflection, frameColor.getHex(), backsheetColor.getHex(), cellWidth, cellHeight, cellEdgeRound, cellEColumn, cellPadding, reflect90Degree);
    }

    function GetDefaultAppearance(color?: THREE.Color, map?: THREE.Texture, transparent = false, side = THREE.FrontSide, notInstanced = false, noDepth = false, isDistanceMap = false, alpha = 1, noInstanceColors = false, hasScale = false) {
        return new THREE.DefaultAppearanceShader(map, color || new THREE.Color(0xFFFFFF), side, transparent, notInstanced, noDepth, isDistanceMap, alpha, noInstanceColors, hasScale);
    }

    function getModuleFrameAppearance(mod?: IModuleAppearance) {
        if (!mod)
            mod = defaultModuleAppearance;
        return GetDefaultAppearance(new THREE.Color(mod.moduleFramingDrawingColor));
    }

    function getModuleElevationAppearance(mod?: IModuleAppearance) {
        return GetDefaultAppearance(new THREE.Color("#333333"));
    }

    export function buildModuleMeshes(mod: IModuleAppearance, config: IClientObjectConfiguration, transforms?: THREE.Matrix4[]) {
        if (!transforms || transforms.length === 0)
            transforms = [new THREE.Matrix4()];

        var withElevationObject = config.WithElevationObject,
            withGroundPlane = config.WithGroundPlane,
            elevationHas90SlopeOnGround = config.ElevationHas90SlopeOnGround,
            modWidth = mod.appearanceWidth,
            modHeight = mod.appearanceHeight,
            depth = mod.appearanceDepth;

        if (depth <= 0)
            depth = 5;

        var vertices = [
            new THREE.Vector3(0, 0, depth), new THREE.Vector3(modWidth, 0, depth), new THREE.Vector3(modWidth, modHeight, depth), new THREE.Vector3(0, modHeight, depth),
            new THREE.Vector3(0, 0, 0), new THREE.Vector3(modWidth, 0, 0), new THREE.Vector3(modWidth, modHeight, 0), new THREE.Vector3(0, modHeight, 0),
        ];
        var uvs = [
            new THREE.Vector2(0, 0), new THREE.Vector2(1, 0), new THREE.Vector2(1, 1), new THREE.Vector2(0, 1),
            new THREE.Vector2(0, 0), new THREE.Vector2(1, 0), new THREE.Vector2(1, 1), new THREE.Vector2(0, 1),
        ];

        var normalMatrix = new THREE.Matrix3(),
            moduleNormal = new THREE.Vector3(0, 0, 1),
            pt = new THREE.Vector3(0, 0, 0),
            off = new THREE.Vector3(config.GeometricStartX || 0, config.GeometricStartY || 0, 0),
            groundPlane = new THREE.Plane().setFromNormalAndCoplanarPoint(moduleNormal, pt),
            ray = new THREE.Ray(),
            geoMod = new THREE.Geometry(),
            geoSides = new THREE.Geometry(),
            geoElevation = new THREE.Geometry();

        transforms.forEach(transform => {
            var geoIndex = geoMod.vertices.length,
                sideIndex = geoSides.vertices.length,
                eleIndex = geoElevation.vertices.length;

            for (var i = 0; i < 8; ++i) {
                var v = vertices[i].clone().applyMatrix4(transform).add(off);
                if (i < 4)
                    geoMod.vertices.push(v);
                geoSides.vertices.push(v);
                if (withElevationObject && i >= 4)
                    geoElevation.vertices.push(v);
            }

            geoMod.faces.push(new THREE.Face3(0 + geoIndex, 1 + geoIndex, 2 + geoIndex));
            geoMod.faces.push(new THREE.Face3(0 + geoIndex, 2 + geoIndex, 3 + geoIndex));

            geoMod.faceVertexUvs[0].push([uvs[0], uvs[1], uvs[2]]);
            geoMod.faceVertexUvs[0].push([uvs[0], uvs[2], uvs[3]]);

            if (!withElevationObject) {
                geoSides.faces.push(new THREE.Face3(4 + geoIndex, 7 + geoIndex, 6 + geoIndex));
                geoSides.faces.push(new THREE.Face3(4 + geoIndex, 6 + geoIndex, 5 + geoIndex));

                geoSides.faceVertexUvs[0].push([uvs[4], uvs[7], uvs[6]]);
                geoSides.faceVertexUvs[0].push([uvs[4], uvs[6], uvs[5]]);
            }

            for (var i = 3, j = 0; j < 4; j++) {

                var a = i + 4,
                    b = j + 4,
                    c = j,
                    d = i;

                geoSides.faces.push(new THREE.Face3(a + sideIndex, b + sideIndex, c + sideIndex));
                geoSides.faces.push(new THREE.Face3(a + sideIndex, c + sideIndex, d + sideIndex));

                geoSides.faceVertexUvs[0].push([uvs[a], uvs[b], uvs[c]]);
                geoSides.faceVertexUvs[0].push([uvs[a], uvs[c], uvs[d]]);

                i = j;
            }

            if (withElevationObject) {
                moduleNormal.set(0, 0, -1);
                if (!elevationHas90SlopeOnGround) {
                    normalMatrix.getNormalMatrix(transform);
                    moduleNormal.applyMatrix3(normalMatrix);
                }

                if (moduleNormal.z >= 0)
                    moduleNormal.set(0, 0, -1);

                for (var i = 0; i < 4; ++i) {
                    var v = geoElevation.vertices[eleIndex + i];
                    if (v.z > 0) {
                        var p = ray.set(v, moduleNormal).intersectPlane(groundPlane, pt);
                        geoElevation.vertices.push(p.clone());
                    } else
                        geoElevation.vertices.push(v.clone());
                }

                geoElevation.faces.push(new THREE.Face3(4 + eleIndex, 7 + eleIndex, 6 + eleIndex));
                geoElevation.faces.push(new THREE.Face3(4 + eleIndex, 6 + eleIndex, 5 + eleIndex));

                geoElevation.faceVertexUvs[0].push([uvs[4], uvs[7], uvs[6]]);
                geoElevation.faceVertexUvs[0].push([uvs[4], uvs[6], uvs[5]]);

                for (var i = 3, j = 0; j < 4; j++) {

                    var a = i + 4,
                        b = j + 4,
                        c = j,
                        d = i;

                    if (geoElevation.vertices[a + eleIndex].z > 0 || geoElevation.vertices[b + eleIndex].z || geoElevation.vertices[c + eleIndex].z) {
                        geoElevation.faces.push(new THREE.Face3(a + eleIndex, b + eleIndex, c + eleIndex));
                        geoElevation.faceVertexUvs[0].push([uvs[a], uvs[b], uvs[c]]);
                    }

                    if (geoElevation.vertices[a + eleIndex].z > 0 || geoElevation.vertices[c + eleIndex].z || geoElevation.vertices[d + eleIndex].z) {
                        geoElevation.faces.push(new THREE.Face3(a + eleIndex, c + eleIndex, d + eleIndex));
                        geoElevation.faceVertexUvs[0].push([uvs[a], uvs[c], uvs[d]]);
                    }

                    i = j;
                }
            }
        });

        if (withGroundPlane) {
            var eleIndex = geoElevation.vertices.length,
                { width, height } = calculateModuleDimension(config, mod.appearanceWidth, mod.appearanceHeight),
                zOff = 10;

            geoElevation.vertices.push(new THREE.Vector3(0, 0, zOff), new THREE.Vector3(width, 0, zOff), new THREE.Vector3(width, height, zOff), new THREE.Vector3(0, height, zOff));
            geoElevation.faces.push(new THREE.Face3(0 + eleIndex, 1 + eleIndex, 2 + eleIndex));
            geoElevation.faces.push(new THREE.Face3(0 + eleIndex, 2 + eleIndex, 3 + eleIndex));

            geoElevation.faceVertexUvs[0].push([new THREE.Vector2(0, 0), new THREE.Vector2(1, 0), new THREE.Vector2(1, 1)]);
            geoElevation.faceVertexUvs[0].push([new THREE.Vector2(0, 0), new THREE.Vector2(1, 1), new THREE.Vector2(0, 1)]);
        }

        var result: THREE.Mesh[] = [];

        geoMod.computeFaceNormals();
        geoMod.computeBoundingSphere();

        geoSides.computeFaceNormals();
        geoSides.computeBoundingSphere();

        var modMat = getModuleAppearance(mod, modWidth, modHeight);
        var sidesMat = getModuleFrameAppearance(mod);

        var modMesh = new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(geoMod), modMat);
        var sidesMesh = new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(geoSides), sidesMat);

        result.push(modMesh);
        result.push(sidesMesh);

        if (geoElevation.vertices.length > 0 && geoElevation.faces.length > 0) {

            geoElevation.computeFaceNormals();

            geoElevation.computeBoundingSphere();

            var eleMat = getModuleElevationAppearance(mod);

            var eleMesh = new THREE.Mesh(new THREE.BufferGeometry().fromGeometry(geoElevation), eleMat);

            result.push(eleMesh);
        }

        return result;
    }

    export function buildSingleModule(mod: IModuleAppearance, config: IClientObjectConfiguration) {
        //einzelnes Modul
        var transform = new THREE.Matrix4();

        var moduleHorizontal = config.ModuleHorizontal,
            roofPaddingZ = config.RoofPaddingZ;

        if (moduleHorizontal)
            transform.makeTranslation(mod.appearanceHeight, 0, roofPaddingZ).multiply(new THREE.Matrix4().makeRotationZ(Math.PI * 0.5));
        else
            transform.makeTranslation(0, 0, roofPaddingZ);

        return buildModuleMeshes(mod, config, [transform]);
    }

    export function buildElevationNS(mod: IModuleAppearance, config: IClientObjectConfiguration) {
        //Südaufständerung
        var transform = new THREE.Matrix4(),
            moduleAngle = config.ElevationAngle,
            moduleHorizontal = config.ModuleHorizontal,
            roofPaddingZ = config.RoofPaddingZ;

        if (moduleHorizontal)
            transform.makeTranslation(0, 0, roofPaddingZ)
                .multiply(new THREE.Matrix4().makeRotationX(moduleAngle / 180 * Math.PI))
                .multiply(new THREE.Matrix4().makeTranslation(mod.appearanceHeight, 0, 0))
                .multiply(new THREE.Matrix4().makeRotationZ(Math.PI * 0.5));
        else
            transform.makeTranslation(0, 0, roofPaddingZ)
                .multiply(new THREE.Matrix4().makeRotationX(moduleAngle / 180 * Math.PI));

        return buildModuleMeshes(mod, config, [transform]);
    }

    export function buildElevationEW(mod: IModuleAppearance, config: IClientObjectConfiguration) {
        //Ost-West Aufständerung
        var transformLeft = new THREE.Matrix4(),
            transformRight = new THREE.Matrix4(),
            moduleAngle = config.ElevationAngle,
            moduleHorizontal = config.ModuleHorizontal,
            roofPaddingZ = config.RoofPaddingZ,
            distanceBetweenLeftAndRight = config.DistanceBetweenLeftAndRight;

        if (moduleHorizontal) {
            var tw = Math.cos(moduleAngle / 180 * Math.PI) * mod.appearanceHeight;

            transformLeft.makeTranslation(0, 0, roofPaddingZ)
                .multiply(new THREE.Matrix4().makeRotationY(-moduleAngle / 180 * Math.PI))
                .multiply(new THREE.Matrix4().makeTranslation(mod.appearanceHeight, 0, 0))
                .multiply(new THREE.Matrix4().makeRotationZ(Math.PI * 0.5));

            transformRight.makeTranslation(tw * 2 + distanceBetweenLeftAndRight, 0, roofPaddingZ)
                .multiply(new THREE.Matrix4().makeRotationY(moduleAngle / 180 * Math.PI))
                .multiply(new THREE.Matrix4().makeRotationZ(Math.PI * 0.5));
        }
        else {
            //vertical
            var tw = Math.cos(15 / 180 * Math.PI) * mod.appearanceWidth;

            transformLeft.makeTranslation(0, 0, roofPaddingZ)
                .multiply(new THREE.Matrix4().makeRotationY(-moduleAngle / 180 * Math.PI));

            transformRight.makeTranslation(tw * 2 + distanceBetweenLeftAndRight, 0, roofPaddingZ)
                .multiply(new THREE.Matrix4().makeRotationY(moduleAngle / 180 * Math.PI))
                .multiply(new THREE.Matrix4().makeTranslation(-mod.appearanceWidth, 0, 0));
        }

        return buildModuleMeshes(mod, config, [transformLeft, transformRight]);
    }

    function buildClientObjects(data: IEditorClientObjectData): SolarProTool.IClient3DObect[] {

        var { instanceHolderId, pvModule, clientObjects } = data;

        var result = Object.keys(clientObjectConfiguration).map(clientObjectType => {
            var config = clientObjectConfiguration[clientObjectType],
                orientation = 0,
                count = 0,
                width: number,
                height: number,
                moduleAppearance: IModuleAppearance,
                dataArrays: SolarProTool.IClientObectDataArrays = {
                    X: [],
                    Y: [],
                    Z: [],
                    SizeX: null,
                    SizeY: null,
                    Id: [],
                    IsActive: [],
                    InstanceColor: null,
                    OptionCode: null,
                    Meta: null
                },
                numModules = 1,
                meshes: THREE.Mesh[],
                cos = clientObjects.filter(co => co.config.ClientObjectType == clientObjectType);

            if (cos.length) {
                cos.forEach(co => {
                    count += co.positions.length;

                    ArrayHelper.iterateArray(co.positions, pos => {
                        dataArrays.X.push(pos.X);
                        dataArrays.Y.push(pos.Y);
                        dataArrays.Z.push(0);
                        dataArrays.Id.push(spt.Utils.GenerateGuid());
                        dataArrays.IsActive.push(1);
                    });

                    width = co.width;
                    height = co.height;
                    moduleAppearance = co.moduleAppearance;
                    config = co.config;
                });
            } else {
                var dim = calculateModuleDimension(config, pvModule.MODULEWIDTH, pvModule.MODULELENGTH);
                width = dim.width;
                height = dim.height;
                moduleAppearance = generateAppearanceFromModule(pvModule);
            }

            switch (config.GeneratorType) {
                case "Module":
                    meshes = buildSingleModule(moduleAppearance, config);
                    break;
                case "NorthSouth":
                    meshes = buildElevationNS(moduleAppearance, config);
                    break;
                case "EastWest":
                    meshes = buildElevationEW(moduleAppearance, config);
                    numModules = 2;
                    break;
            }

            var clientObject: SolarProTool.IClient3DObect = {
                SharedMeshes: meshes,
                Count: count,
                NumModules: numModules,
                ModCount: numModules,
                Orientation: orientation,
                GeoWidth: width,
                GeoHeight: height,
                IconInsertSet: config.IconSet,
                IconInsertIndex: config.IconIndex,
                GroupIconInsert: null,
                GroupIconInsertSet: config.IconSet,
                GroupIconInsertIndex: config.IconIndex,
                DataArrays: dataArrays,
                Options: config.Options,
                Type: config.ClientObjectType,
                TypeId: null,
                BaseParentId: instanceHolderId,
                Transparency: 0,
                IsHidden: false
            };

            return clientObject;
        });

        return result;
    }

    //#endregion

    //#region generate, snapping, insert, delete and move

    export function regenerateLayoutWithModuleId(pvModuleId: string) {
        if (LoaderManager.isLoading())
            return;

        layoutModuleId = pvModuleId;

        Controller.Current.regenerateModulePlan(false);
    }

    export function regenerateLayout(clientObject: ClientObject) {
        if (LoaderManager.isLoading() || !clientObject || !clientObject.dataType || !clientObjectConfiguration[clientObject.dataType])
            return;

        layoutClientObjectType = clientObject.dataType;

        Controller.Current.regenerateModulePlan(false);
    }

    //returns a translation that can be applied to the bounds if snapping is successful. returns null if there are any intersections.
    function snap(bounds: THREE.Box3[], config: IClientObjectConfiguration, target: THREE.Vector3, excludeIds: string[] = [], withoutCheck?: boolean, showErrorInfo?: boolean): THREE.Vector3 {
        if (!bounds || !bounds.length || !config)
            return null;

        var maxSnapDistance = 1000,
            controller = LS.Client3DEditor.Controller.Current,
            viewModel = controller.viewModel,
            instanceContext = viewModel.currentInstance,
            searchbox = new THREE.Box3(),
            tb = new THREE.Box3(),
            paddingx = config.DistanceX,
            paddingy = config.DistanceY,
            targetDist = Number.MAX_VALUE;

        for (var i = bounds.length; i--;)
            searchbox.union(bounds[i]);

        searchbox.expandByScalar(maxSnapDistance);

        var instances = (instanceContext.getBoxIntersections(searchbox) as ClientObjectInstance[]).filter(inst => excludeIds.indexOf(inst.instanceData.Id) === -1);

        target.set(0, 0, 0);

        if (instances.length) {
            instances.forEach(instance => {
                var dataType = instance.clientObject.dataType,
                    cfg = dataType ? clientObjectConfiguration[dataType] : null;

                if (!cfg)
                    return;

                var padx = Math.max(cfg.DistanceX, paddingx),
                    pady = Math.max(cfg.DistanceY, paddingy),
                    instanceData = instance.instanceData;

                calculateBounds(instance.clientObject, instanceData.X, instanceData.Y, tb.makeEmpty());

                bounds.forEach(bd => {
                    var tr = calculateSnappingTranslation(bd, tb, padx, pady),
                        tdist = tr.lengthSq();
                    if (tdist < targetDist) {
                        target.copy(tr);
                        targetDist = tdist;
                    }
                });
            });
        }

        if (targetDist > maxSnapDistance * maxSnapDistance)
            target.set(0, 0, 0);

        return !withoutCheck && bounds.some(b => hasIntersections(b, config.DistanceToInterferences, excludeIds, target, showErrorInfo)) ? null : target;
    }

    var _box = new THREE.Box3(),
        _interBox = new THREE.Box3(),
        _bdVecs = [new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()];

    function hasIntersections(bd: THREE.Box3, distanceToInterference: number = 200, excludeIds: string[] = [], offset?: THREE.Vector3, showErrorInfo?: boolean) {
        var threshold = -0.5,
            controller = LS.Client3DEditor.Controller.Current,
            viewModel = controller.viewModel,
            instanceContext = viewModel.currentInstance,
            bounds = offset ? _box.copy(bd).translate(offset).expandByScalar(threshold) : _box.copy(bd).expandByScalar(threshold),
            interferenceObjects = viewModel.interferenceObjects as DynamicInterferenceObject[];

        var pts = setVecsFromBounds(_bdVecs, bounds);

        if (pts.some(p => !MapDrawing.Point2D.isInsidePolygon(p, instanceContext.instanceAreaPolygons[0], 0.01))) {
            if (showErrorInfo)
                DManager.ShowSmallInfo(window.translations["ObjectOutsideRoof"]);// "Object is outside of roof.")
            return true;
        }

        var boundsForInterference = _interBox.copy(bounds).expandByScalar(distanceToInterference),
            interferenceIntersection = interferenceObjects.some(inter => {
                console.log(inter.localPolygon.array);
                if (pts.some(p => MapDrawing.Point2D.isInsidePolygon(p, inter.localPolygon.array, 0.01)))
                    return true;
                if (!inter.instanceMesh)
                    return false;
                return spt.ThreeJs.utils.BoxisIntersectingMesh(boundsForInterference, inter.instanceMesh);
            });

        if (interferenceIntersection) {
            if (showErrorInfo)
                DManager.ShowSmallInfo(window.translations["ObjectIntersectsInterference"]);// "Object intersects with interference object.")
            return true;
        }

        var instances = (instanceContext.getBoxIntersections(bounds) as ClientObjectInstance[]).filter(inst => excludeIds.indexOf(inst.instanceData.Id) === -1);

        if (instances.length) {
            if (showErrorInfo)
                DManager.ShowSmallInfo(window.translations["ObjectIntersectsObject"]);//"Object intersects with another object.")
            return true;
        }

        return false;
    }

    //when snapping between two bounds, there are exactly 12 possible snap targets. from = index of vertex of target bounds, to = index of vertrex of source bounds, px = apply paddingx, py = apply paddingx
    var snappingTargets = [
        { from: 0, to: 1, px: -1, py: 0 },
        { from: 0, to: 2, px: -1, py: -1 },
        { from: 0, to: 3, px: 0, py: -1 },
        { from: 1, to: 2, px: 0, py: -1 },
        { from: 1, to: 3, px: 1, py: -1 },
        { from: 1, to: 0, px: 1, py: 0 },
        { from: 2, to: 3, px: 1, py: 0 },
        { from: 2, to: 0, px: 1, py: 1 },
        { from: 2, to: 1, px: 0, py: 1 },
        { from: 3, to: 0, px: 0, py: 1 },
        { from: 3, to: 1, px: -1, py: 1 },
        { from: 3, to: 2, px: -1, py: 0 }
    ];

    var _sourceVecs = [new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()],
        _targetVecs = [new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()],
        _v1 = new THREE.Vector3(),
        _v2 = new THREE.Vector3(),
        _tb = new THREE.Box3();

    function setVecsFromBounds(vec: THREE.Vector3[], bounds: THREE.Box3) {
        var min = bounds.min,
            max = bounds.max;

        vec[0].set(min.x, min.y, 0);
        vec[1].set(max.x, min.y, 0);
        vec[2].set(max.x, max.y, 0);
        vec[3].set(min.x, max.y, 0);

        return vec;
    }

    //manually check all 12 possible snapping positions and return the translation to the closest target
    function calculateSnappingTranslation(source: THREE.Box3, target: THREE.Box3, padx: number, pady: number) {
        var toVecs = setVecsFromBounds(_sourceVecs, source),
            fromVecs = setVecsFromBounds(_targetVecs, target),
            dist = Number.MAX_VALUE,
            dt = _v1,
            move = _v2,
            fromVec: THREE.Vector3,
            toVec: THREE.Vector3,
            st: { from: number, to: number, px: number, py: number },
            d: number;

        for (var i = 12; i--;) {
            st = snappingTargets[i];
            fromVec = fromVecs[st.from];
            toVec = toVecs[st.to];
            dt.set(fromVec.x + padx * st.px, fromVec.y + pady * st.py, 0);
            d = toVec.distanceToSquared(dt);
            if (d < dist) {
                dist = d;
                move.set(dt.x - toVec.x, dt.y - toVec.y, 0);
            }
        }

        return move;
    }

    async function InsertClientObjects(data: { clientObjectsParams: SolarProTool.InsertClientObjectsParams[], ctrlDown: boolean, disableSnapping: boolean, compressData: boolean }) {
        var { clientObjectsParams, ctrlDown, disableSnapping } = data,
            controller = LS.Client3DEditor.Controller.Current,
            changes: SolarProTool.ClientObjectChanges[] = [],
            clientObjectsDatas: SolarProTool.InsertClientObjectsData[] = [],
            vt = new THREE.Vector3();

        if (clientObjectsParams && clientObjectsParams.length) {
            clientObjectsParams.forEach(clientObjectsParam => {
                if (!clientObjectsParam.Positions || !clientObjectsParam.Positions.length)
                    return;

                var clientObjects: SolarProTool.IClientObject[] = [],
                    withoutCheck = false, //ctrlDown,
                    type = clientObjectsParam.TypeString,
                    config = clientObjectConfiguration[type],
                    clientObject = controller.Objects[clientObjectsParam.SourceId],
                    bounds = clientObjectsParam.Positions.map(pos => calculateBounds(clientObject, Math.round(pos.x), Math.round(pos.y))),
                    offset = snap(bounds, config, vt, [], withoutCheck, !withoutCheck);

                if (offset) {
                    for (var i = 0, l = bounds.length; i < l; ++i) {
                        var bd = bounds[i];

                        clientObjects.push({
                            X: bd.min.x + offset.x,
                            Y: bd.min.y + offset.y,
                            Z: bd.min.z + offset.z,
                            Id: spt.Utils.GenerateGuid(),
                            IsActive: 1,
                            InstanceColor: 0,
                            OptionCode: 0
                        });
                    }
                }

                if (clientObjects.length > 0) {
                    clientObjects = clientObjects.distinctByKey(m => m.Id);

                    var result: SolarProTool.ClientObectDataArrays = {
                        X: [],
                        Y: [],
                        Z: [],
                        SizeX: [],
                        SizeY: [],
                        Id: [],
                        IsActive: [],
                        InstanceColor: [],
                        OptionCode: [],
                        Meta: []
                    };

                    clientObjects.forEach(co => {
                        result.X.push(co.X);
                        result.Y.push(co.Y);
                        result.Z.push(co.Z);
                        result.InstanceColor.push(co.InstanceColor);
                        result.OptionCode.push(co.OptionCode);
                        result.IsActive.push(co.IsActive);
                        result.Id.push(co.Id);
                    });

                    if (result.Id.length) {
                        clientObjectsDatas.push(
                            {
                                ClientObectData: result,
                                SourceId: clientObjectsParam.SourceId
                            });
                    }
                }
            });
        }

        var result: SolarProTool.InsertClientObjectsResult =
        {
            ClientObjectsDatas: clientObjectsDatas,
            Changes: changes
        };

        return result;
    }

    async function InsertClientObjectByRect(data: { x1: number, y1: number, x2: number, y2: number, orientation: number, typeString: string, typeId: string, disableSnapping: boolean }) {
        let [editorData, clientObjectData] = await Promise.all([GetDataForEditor3D(), GetDataForClientObjects()]);
        var { x1, y1, x2, y2, typeString } = data,
            bounds = _tb.makeEmpty()
                .expandByPoint(_v1.set(Math.round(x1), Math.round(y1), -4000))
                .expandByPoint(_v1.set(Math.round(x2), Math.round(y2), 4000));

        //TODO snap bounds corners to client objects (like in SPT)

        var controller = LS.Client3DEditor.Controller.Current,
            viewModel = controller.viewModel,
            instanceContext = viewModel.currentInstance,
            layoutMask: SolarProTool.Point2D[] = [
                { X: bounds.min.x, Y: bounds.min.y },
                { X: bounds.max.x, Y: bounds.min.y },
                { X: bounds.max.x, Y: bounds.max.y },
                { X: bounds.min.x, Y: bounds.max.y }
            ];

        var instances = (instanceContext.getBoxIntersections(bounds) as ClientObjectInstance[]),
            instancePolys = instances.map(instance => {
                var instanceData = instance.instanceData,
                    bd = calculateBounds(instance.clientObject, instanceData.X, instanceData.Y, _tb.makeEmpty());
                var poly: SolarProTool.Point2D[] = [
                    { X: bd.min.x, Y: bd.min.y },
                    { X: bd.max.x, Y: bd.min.y },
                    { X: bd.max.x, Y: bd.max.y },
                    { X: bd.min.x, Y: bd.max.y }
                ];
                return poly;
            });

        var layoutData = await generateLayout(clientObjectData.instanceHolderId, editorData, typeString, clientObjectData.pvModule, layoutMask, instancePolys);

        if (layoutData && layoutData.clientObjects) {
            //add new positions to existing client objects
            layoutData.clientObjects.forEach(clientObject => {
                var clientObjectType = clientObject.config.ClientObjectType,
                    co = clientObjectData.clientObjects.find(c => c.config.ClientObjectType === clientObjectType);
                if (co)
                    co.positions.pushAll(clientObject.positions);
            });
        }

        var result: SolarProTool.InsertClientObjectByRectResult = {
            ClientObects: buildClientObjects(clientObjectData),
            Changes: []
        };

        return result;
    }

    async function DeleteClientObjects(data: { changes: LS.Client3DEditor.IChange[] }) {
        var result = data.changes.map(change => {
            var res: SolarProTool.ClientObjectChanges = {
                TypeString: change.TypeString,
                UpdateType: change.UpdateType,
                IdStrings: change.IdStrings,
                ErrorCode: 0
            };
            return res;
        });

        return result;
    }

    async function HandleClientObjectChanges(data: { changes: LS.Client3DEditor.IChange[], revert: boolean, disableSnapping: boolean }) {
        var result: SolarProTool.ClientObjectChanges[] = [],
            { changes, revert, disableSnapping } = data,
            controller = LS.Client3DEditor.Controller.Current,
            vt = new THREE.Vector3();

        changes.forEach(change => {

            var type = change.TypeString,
                clientObjects = change.IdStrings.map(id => controller.Instances[id]).filter(o => !!o),
                config = clientObjectConfiguration[type];

            //Move clientObject
            if (change.UpdateType == "translate") {
                if (!revert) {
                    var bounds = clientObjects.map(inst => calculateBounds(inst.clientObject, Math.round(inst.position.x), Math.round(inst.position.y))),
                        offset = snap(bounds, config, vt, change.IdStrings, revert, !revert);

                    if (!offset) {
                        //revert change
                        result.push(
                            {
                                ErrorCode: 1,
                                Uuid: change.Uuid
                            });
                    }
                    else if (offset.lengthSq() > 0) {
                        //add snapping offset
                        result.push(
                            {
                                TypeString: change.TypeString,
                                UpdateType: "translate",
                                ErrorCode: 0,
                                Tx: offset.x,
                                Ty: offset.y,
                                Tz: offset.z,
                                Sx: 0,
                                Sy: 0,
                                IdStrings: change.IdStrings
                            });
                    }
                }
            }
        });

        return result;
    }

    export function getDefaultModuleType(roofType: RoofType) {
        return roofType === RoofType.FlatRoof ? "EastWestElevation" : "VerticalModule";
    }

    async function GenerateWithGetClient3DObects(data: { withCorridors: boolean }) {

        let [editorData, clientObjectData] = await Promise.all([GetDataForEditor3D(), GetDataForClientObjects()]);

        var pvModuleId: string,
            clientObjectType: string,
            pvModule: IPVModule;

        //debugger;


        if (!clientObjectData || !clientObjectData.clientObjects.length) {
            //automatic layout if no clientobjects present or pvmodule has changed
            pvModuleId = selectableModuleIds[0];
            clientObjectType = getDefaultModuleType(editorData.roofType); // Object.keys(clientObjectConfiguration)[0];
            pvModule = await lsAo3d.getPvModule(pvModuleId);
        } else {
            pvModule = clientObjectData.pvModule;
            pvModuleId = pvModule.PVModuleId;
            clientObjectType = getDefaultModuleType(editorData.roofType); // clientObjectData.clientObjects[0].config.ClientObjectType;
        }

        if (layoutClientObjectType)
            clientObjectType = layoutClientObjectType;

        if (layoutModuleId && layoutModuleId !== pvModuleId) {
            pvModuleId = layoutModuleId;
            pvModule = await lsAo3d.getPvModule(pvModuleId);
        }

        layoutClientObjectType = null;
        layoutModuleId = null;

        clientObjectData = await generateLayout(clientObjectData.instanceHolderId, editorData, clientObjectType, pvModule);

        await Promise.all([SaveDataForEditor3D(editorData), SaveDataForClientObjects(clientObjectData)]);

        var result: SolarProTool.Client3DObjectHolder = {
            ClientObjects: buildClientObjects(clientObjectData)
        };

        return result;
    }

    async function SetInterferenceStates(data: { idStrings: string[], baseParentIds: string[], states: SolarProTool.IInterference3DObject[] }) {
        return data && data.states && data.states.length ? data.states.filter(state => !!state).map(state => state.IdString) : null;
    }

    //#endregion

}